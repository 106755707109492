/* colors */
/*@blue:#415f8c;*/
/* PADDING */
/* TOOLBAR */
/*Toolbar colors*/
/*Toolbar button colors*/
/*Toolbar form elements*/
/*Toolbar dropdown menu*/
.altai-admin-toolbar.development,
.altai-theme-admin .Development .window .window-header,
.altai-theme-admin .Development .content-toolbar {
  background-color: green !important;
  border-color: #005000 !important;
}
.altai-theme-admin {
  /*ui-layout fix for altai*/
  /*padding*/
  /*multi-select*/
}
.altai-theme-admin md-tabs-wrapper,
.altai-theme-admin md-content.md-default-theme {
  background-color: #fafafa !important;
}
.altai-theme-admin textarea {
  width: 100%;
}
.altai-theme-admin .altai-content-attribute-update-item-wrapper {
  display: flex;
}
.altai-theme-admin :focus {
  outline: none;
}
.altai-theme-admin .language-buttons {
  flex: 1;
  justify-content: center;
}
.altai-theme-admin .flex-wrapper {
  display: flex;
}
.altai-theme-admin .wm-container md-checkbox .md-container:after {
  top: -8px;
  right: -8px;
  bottom: -8px;
  left: -8px;
}
.altai-theme-admin .wm-container md-checkbox .md-container .md-ripple-container {
  left: -16px;
  top: -16px;
  right: -16px;
  bottom: -16px;
}
.altai-theme-admin .wm-container md-checkbox.md-default-theme.md-checked .md-icon,
.altai-theme-admin .wm-container md-checkbox.md-checked .md-icon {
  background-color: rgba(0, 81, 255, 0.87);
}
.altai-theme-admin .wm-container md-checkbox .md-icon {
  width: 16px;
  height: 16px;
}
.altai-theme-admin .wm-container md-checkbox.md-checked .md-icon:after {
  left: 5.66667px;
  top: 0.22222px;
  width: 6.66667px;
  height: 13.33333px;
}
.altai-theme-admin .wm-container md-checkbox.md-default-theme.md-checked .md-ink-ripple,
.altai-theme-admin .wm-container md-checkbox.md-checked .md-ink-ripple {
  color: rgba(0, 87, 222, 0.87);
}
.altai-theme-admin .wm-container md-checkbox .md-container {
  width: 16px;
  height: 16px;
}
.altai-theme-admin .wm-container md-checkbox {
  margin-bottom: 0;
}
.altai-theme-admin .wm-container md-checkbox .md-label {
  margin-top: 6px;
}
.altai-theme-admin .altai-content-relation-update {
  flex: 1;
  display: flex;
  flex-direction: row;
}
.altai-theme-admin altai-content-relation-update {
  flex: 1;
  display: flex;
}
.altai-theme-admin altai-content-attribute-update {
  flex: 1;
}
.altai-theme-admin .mainbuttonrow {
  margin-top: 50px;
}
.altai-theme-admin .relation-form-group {
  display: flex;
  flex: 1;
}
.altai-theme-admin .fr-box {
  flex: 1;
  border: 1px solid #eee;
}
.altai-theme-admin .content-relation-item-group {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.altai-theme-admin .spacer-vertical-5 {
  min-height: 5px;
  height: 5px;
}
.altai-theme-admin .spacer-vertical-10 {
  min-height: 10px;
  height: 10px;
}
.altai-theme-admin .spacer-vertical-20 {
  min-height: 20px;
  height: 20px;
}
.altai-theme-admin .spacer-vertical-40 {
  min-height: 40px;
  height: 40px;
}
.altai-theme-admin .spacer-vertical-60 {
  min-height: 60px;
  height: 60px;
}
.altai-theme-admin .spacer-vertical-80 {
  min-height: 80px;
  height: 80px;
}
.altai-theme-admin .spacer-vertical-100 {
  min-height: 100px;
  height: 100px;
}
.altai-theme-admin .spacer-vertical-200 {
  min-height: 200px;
  height: 200px;
}
.altai-theme-admin .spacer-vertical-400 {
  min-height: 400px;
  height: 400px;
}
.altai-theme-admin .md-button-altai {
  padding: 0 5px 0 5px;
  margin: 2px 0px 2px 10px;
  border-radius: 3px;
  line-height: 25px;
  min-height: 25px;
  font-size: 12px;
  text-transform: none;
  font-weight: 500;
}
.altai-theme-admin .cke_chrome {
  border-top: 4px solid #e3e3e3;
  /* border-bottom: 4px solid #0068ff; */
}
.altai-theme-admin .stretch-outer {
  top: 85px !important;
}
.altai-theme-admin .ui-layout-column > .ui-splitbar {
  background: fixed #dedede !important;
}
.altai-theme-admin .toppadding-small {
  padding-top: 20px;
}
.altai-theme-admin .multiSelect .multiSelectItem .acol label {
  font-size: 13px;
}
.altai-theme-admin .wide-inputfield {
  width: 100%;
}
.altai-theme-admin .wide-inputfield .input-container {
  width: 100%;
}
.no-animate {
  -webkit-transition: none !important;
  transition: none !important;
}
.no-select {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  not supported by any browser */
  cursor: default;
}
.altai-content-table-header-sorter:hover {
  cursor: pointer;
}
.altai-toast {
  position: fixed;
  bottom: 0;
  right: 0;
}
md-toast {
  position: fixed !important;
  bottom: 10px;
  right: 10px;
}
.altai-date-picker-error {
  background: #f8dbdb;
  border-color: #e77776;
  /*float: right;
    padding-bottom: 8px;
    width: 50% !important;
    padding-top: 8px;*/
}
